<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><i class="fi-torso-female"></i>&nbsp;&nbsp;&nbsp;Mein Konto</h3></div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <md-card>
          <div class="grid-x grid-padding-x">
            <div class="cell medium-4 large-3">
              <md-card-media>
                <img src="../assets/icons/user-account-woman.png" alt="Mein Konto">
              </md-card-media>
            </div>
            <div class="cell medium-8 large-9">

              <md-card-header>
                <div class="md-title">Benutzer-Konto</div>
                <div class="md-subhead">{{user.email}}</div><br>
                <div v-if="user.loginTime" class="md-subhead" style="opacity: 1;">Aktuelle Anmeldung : <span style="color: crimson;">{{user.loginTime | moment("LLLL")}} Uhr</span></div>
                <div v-if="user.lastLoginTime" class="md-subhead" style="opacity: 1;">Letzte Anmeldung : <span style="color: crimson;">{{user.lastLoginTime | moment("LLLL")}} Uhr</span></div>
                <div v-if="user.invalidLoginAttempts > 0" class="md-subhead" style="opacity: 1;">Ungültige Anmeldeversuche : <span style="color: crimson;">{{user.invalidLoginAttempts}}</span></div>

                <div v-if="isPseudoSage">
                  <br>
                  <button class="button success" @click="resetIdentity">
                    <md-icon style="height: 12px">fingerprint</md-icon><md-icon style="height: 12px">clear</md-icon>Identität ablegen
                  </button>
                </div>
              </md-card-header>

              <md-card-expand>
                <md-card-actions md-alignment="left">
                  <md-card-expand-trigger>
                    <md-button id="expand-user-details-button-id" class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                    </md-button>
                  </md-card-expand-trigger>

                  <div>
                    <label>&nbsp;&nbsp;Details</label>
                  </div>
                </md-card-actions>

                <md-card-expand-content>
                  <md-card-content>
                    <UserView :user="currentUser"></UserView>
                    <br>
                    <div @click="editUserDialog" class="button"><i class="fi-pencil"></i>&nbsp;&nbsp;Daten bearbeiten
                    </div>
                  </md-card-content>
                </md-card-expand-content>
              </md-card-expand>
            </div>
          </div>
        </md-card>
      </div>
    </div>

    <EditUserDialog :showDialog="showEditUserDialog" :user="currentUser" :mode="dialogMode"
                    :availableFacilities="availableFacilities" :availableCustomerNo="availableCustomerNo"
                    :availableCustomerNos="availableCustomerNos"
                    @updateUserSuccess="updateUserSuccess"
                    @closeEditUserDialog="showEditUserDialog = false"></EditUserDialog>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import UserService from '../services/UserService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import UserView from '../components/views/UserView';
  import EditUserDialog from '../components/dialogs/EditUserDialog';
  import User from '../entities/User';
  import Organization from '../entities/Organization';

  export default {
    name: 'User',
    components: {
      EditUserDialog,
      UserView,
      User,
      'vue-simple-spinner': Spinner
    },

    mounted() {
      if (this.organization.facilities) {
        this.availableFacilities.push({name: 'Alle', referenceNumber: '*'});
        this.availableFacilitiesMap['*'] = 'Alle';
        for (let facility of this.organization.facilities) {
          this.availableFacilities.push({name: facility.name, referenceNumber: facility.referenceNumber});
          this.availableFacilitiesMap[facility.referenceNumber] = facility.name;
        }
      }

      this.reloadUser();
    },

    data() {
      return {
        sending: false,
        success: false,
        dialogMode: '',
        currentUser: null,
        showEditUserDialog: false,
        availableFacilities: [],
        availableFacilitiesMap: {},
        currentUserName: '',
        availableCustomerNo: [],
        availableCustomerNos: {},
      }
    },

    methods: {
      editUserDialog() {
        this.dialogMode = 'update';
        this.refreshUser();
        this.showEditUserDialog = true;
      },

      updateUserSuccess() {
        this.showEditUserDialog = false;
        this.currentUserName = this.currentUser.firstName + ' ' + this.currentUser.lastName;

        if (this.dialogMode === 'update') {
          this.$store.commit('successMsg', 'Die Änderungen für Benutzer&nbsp;<b>' + this.currentUserName + '</b>&nbsp;wurden erfolgreich gespeichert!');
          this.success = true;
        }

        this.success = true;

        this.reloadUser();
      },

      reloadUser() {
        UserService.getUserInfo()
          .then(response => {
            this.$store.commit('userInfo', response.data);
            this.refreshUser();
            setTimeout(() => jQuery('#expand-user-details-button-id').click(), 250);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Benutzerdaten');
            this.sending = false;
          });
      },

      refreshUser() {
        this.currentUser = JSON.parse(JSON.stringify(this.user));
        if (this.currentUser.allowedFacilities === null) {
          this.currentUser.allowedFacilities = [];
        }
        this.currentUser.initialpw = '';
        this.currentUser.allowedFacilitiesString = '';
        if (this.currentUser.allowedFacilities) {
          let all = false;
          for (let facility of this.currentUser.allowedFacilities) {
            if (facility === '*') {
              all = true;
              let j = 1;
              this.currentUser.allowedFacilitiesString = 'Alle :&nbsp;';
              for (let fa of this.availableFacilities) {
                if (fa.referenceNumber !== '*') {
                  this.currentUser.allowedFacilitiesString += '(' + j++ + ') ' + this.availableFacilitiesMap[fa.referenceNumber] + '&nbsp;';
                }
              }
            }
          }
          if (!all) {
            let j = 1;
            for (let facility of this.currentUser.allowedFacilities) {
              this.currentUser.allowedFacilitiesString += '(' + j++ + ') ' + this.availableFacilitiesMap[facility] + '&nbsp;';
            }
          }
        }
      },

      resetIdentity() {
        this.sending = true;
        this.success = false;
        UserService.resetIdentity()
          .then(response => {
            this.$store.commit('userInfo', response.data);
            this.$store.commit('organizationInfo', Organization.ai42Organization);
            this.sending = false;
            this.$store.commit('successMsg', 'Identitätsübernahme wurde erfolgreich beendet.');
            this.success = true;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Beenden der Identitätsübernahme');
            this.sending = false;
          })
      },
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      user() {
        return this.$store.getters.user;
      },

      isPseudoSage() {
        return this.$store.getters.pseudoSage;
      },

      organization() {
        return this.$store.getters.organization;
      },
    }
  }
</script>

<style lang="scss" scoped>
  i {
    color: black;
  }

  .md-card-media img {
    padding: 20px;
    width: 220px;
  }
</style>